/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  margin-bottom: 18px;
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return null

  return (
    <div className="bio">
      <Navigation>
        <Column>
          <Item>Blog</Item>
          <Item>About</Item>
        </Column>
        <Column>
          <Item>Tools</Item>
          <Item>Contact</Item>
        </Column>
      </Navigation>
      {/*<StaticImage*/}
        {/*className="bio-avatar"*/}
        {/*layout="fixed"*/}
        {/*formats={["AUTO", "WEBP", "AVIF"]}*/}
        {/*src="../images/profile-pic.png"*/}
        {/*width={50}*/}
        {/*height={50}*/}
        {/*quality={95}*/}
        {/*alt="Profile picture"*/}
      {/*/>*/}
      {/*{author?.name && (*/}
        {/*<p>*/}
          {/*Written by <strong>{author.name}</strong> {author?.summary || null}*/}
          {/*{` `}*/}
          {/*<a href={`https://twitter.com/${social?.twitter || ``}`}>*/}
            {/*You should follow them on Twitter*/}
          {/*</a>*/}
        {/*</p>*/}
      {/*)}*/}
    </div>
  )
}

export default Bio
